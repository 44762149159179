<template>
    <div>
        <h3 class="mb-6 text-lg font-semibold">How long is your event?</h3>

        <ul class="grid grid-cols-1 gap-y-3 text-sm at720:grid-cols-2">
            <li
                v-for="(duration, idx) in durations"
                class="flex flex-row items-center space-x-4"
            >
                <input
                    :id="`modal-filter-duration-${idx}`"
                    @change="handleSelection(duration.value, $event)"
                    type="checkbox"
                    :value="duration.value"
                    class="rounded border-gray-300"
                    :checked="
                        findIndex(
                            selectedDurations,
                            (v) => v === duration.value
                        ) > -1
                    "
                />
                <label :for="`modal-filter-duration-${idx}`">{{
                    duration.label
                }}</label>
            </li>
        </ul>
    </div>
</template>

<script setup>
import { useCollectionFilters } from '@/composables/useCollectionFilters';
import findIndex from 'lodash/findIndex';
import { ref } from 'vue';

const props = defineProps({
    current: {
        required: false,
        default: null,
    },
});

const { filterOptions, queueFilter } = useCollectionFilters();
const durations = filterOptions.durations;
const selectedDurations = ref(props.current ? [...props.current] : []);

const handleSelection = (duration, event) => {
    if (event.target.checked) {
        selectedDurations.value.push(duration);
        selectedDurations.value.sort();
    } else {
        try {
            let index = findIndex(
                selectedDurations.value,
                (v) => v == duration
            );
            if (index !== -1) {
                selectedDurations.value.splice(index, 1);
            }
        } catch (e) {}
    }
    queueFilter('durations', selectedDurations.value);
};
</script>
