<template>
    <div class="at480:py-0 at1024:max-w-standard">
        <div
            class="at768:grid at768:max-h-hero"
            :class="{
                'at768:grid-cols-4 at768:grid-rows-2 at768:gap-2 at1200:gap-4':
                    images.length === 5,
                'at768:min-h-hero at768:grid-cols-3 at768:gap-2 at1200:gap-4':
                    images.length === 4 || images.length === 3,
                'at768:grid-cols-2 at768:gap-2 at1200:gap-4':
                    images.length === 2,
            }"
        >
            <gallery-image
                v-for="(image, idx) in imageArray"
                @click.prevent="handleImageClick(idx)"
                :video="idx === 0 && video ? video : ''"
                :image="image"
                :class="{
                    'hidden at768:block': idx > 0,
                    'at768:col-span-2 at768:row-span-2':
                        idx === 0 && images.length === 5,
                    'cursor-pointer': shouldOpenModal,
                }"
            />
        </div>
        <modals-container></modals-container>
    </div>
</template>

<script setup>
import { ModalsContainer, useModal } from 'vue-final-modal';
import GalleryImage from './Gallery/GalleryImage';
import GalleryImageModal from './Gallery/GalleryImageModal';

const props = defineProps({
    images: {
        type: Array,
        required: true,
    },
    video: {
        type: String,
        required: false,
        default: () => '',
    },
});

const imageArray = props.images;

if (imageArray.length === 4) {
    imageArray.pop();
}

const shouldOpenModal = props.video || props.images.length > 1;

const { open, close, patchOptions } = useModal({
    component: GalleryImageModal,
    attrs: {
        images: props.images,
        video: props.video,
        onClose: () => {
            close();
        },
    },
});

const handleImageClick = (idx) => {
    if (!shouldOpenModal) {
        return false;
    }

    patchOptions({
        attrs: {
            initialIdx: idx,
        },
    });

    open();
};
</script>

<style scoped></style>
