<template>
    <DisclosurePanel v-if="product.hasShipments && product.host">
        <div
            class="mb-2 flex items-center text-base font-medium at1024:space-x-2"
        >
            <truck-icon
                class="hidden h-7 w-7 shrink-0 stroke-1 at1024:inline-block"
            ></truck-icon>
            <span
                >This event includes a shipment to
                {{
                    product.isInPerson
                        ? 'your address'
                        : "each guest's address"
                }}.</span
            >
        </div>

        <p>
            You'll have to provide
            {{ product.isInPerson ? 'an address' : 'addresses' }}
            <span class="font-semibold"
                >{{ product.host.leadTime }}
                {{ product.host.leadTime === 1 ? 'day' : 'days' }}</span
            >
            prior to the event to allow for shipping and delivery.
        </p>

        <div
            v-if="showShippingDetail"
            class="space-y-4 rounded bg-gray-200 p-5"
        >
            <div class="flex align-middle text-black">
                <check-icon
                    v-if="product.host.shipping.international"
                    class="mr-1.5 h-5 w-5 text-green-600"
                ></check-icon>
                <x-icon v-else class="mr-1 h-5 w-5 text-red-500"></x-icon>
                <globe-icon class="mr-2 h-5 w-5"></globe-icon>
                <span class="leading-5">
                    International shipping
                    <template v-if="product.host.shipping.international">
                        is available but is not included in the price and will
                        incur additional costs
                    </template>
                    <template v-else>
                        is not available for this event
                    </template>
                </span>
            </div>

            <div class="flex align-middle text-black">
                <check-icon
                    v-if="product.host.shipping.expedited"
                    class="mr-1.5 h-5 w-5 text-green-600"
                ></check-icon>
                <x-icon v-else class="mr-1 h-5 w-5 text-red-500"></x-icon>
                <truck-icon class="mr-2 h-5 w-5"></truck-icon>
                <span class="leading-5">
                    Expedited shipping
                    <template v-if="product.host.shipping.expedited">
                        is available but is not included in the price and will
                        incur additional costs
                    </template>
                    <template v-else>
                        is not available for this event
                    </template>
                </span>
            </div>

            <div
                v-if="product.host.shipping.customMessages"
                class="flex align-middle text-black"
            >
                <check-icon
                    v-if="product.host.shipping.international"
                    class="mr-1.5 h-5 w-5 text-green-600"
                ></check-icon>
                <chat-alt-icon class="mr-2 h-5 w-5"></chat-alt-icon>
                <span class="leading-5"
                    >Custom messages are available to include in shipments</span
                >
            </div>
        </div>

        <div
            v-if="
                showShippingDetail &&
                product.host.shipping.international &&
                intlShippingCountries.length > 0
            "
        >
            <div class="font-semibold">
                <span
                    >International shipping is available to the following
                    {{
                        intlShippingCountries.length > 1
                            ? 'destinations'
                            : 'destination'
                    }}:</span
                >
            </div>
            <expandable-set
                limit="20"
                :items="intlShippingCountries"
                class="mt-4"
            ></expandable-set>
        </div>

        <template v-if="showShippingDetail && product.host.shipping.policy">
            <h5 class="font-semibold">Shipping policy or exclusions</h5>
            <p class="whitespace-break-spaces">
                {{ product.host.shipping.policy }}
            </p>
        </template>
    </DisclosurePanel>
</template>

<script setup>
import { DisclosurePanel } from '@headlessui/vue';
import {
    ChatAltIcon,
    CheckIcon,
    GlobeIcon,
    TruckIcon,
    XIcon,
} from '@heroicons/vue/outline';
import ExpandableSet from 'components/Global/ExpandableSet.vue';
import { computed, inject } from 'vue';

const product = inject('product');

const showShippingDetail = computed(() => product.isVirtual); // detailed shipping info is only shown for virtual events
const intlShippingCountries = computed(() =>
    product.host.shipping.availableShippingCountries
        ? Object.values(product.host.shipping.availableShippingCountries)
        : []
);
</script>

<style scoped></style>
