<template>
    <DisclosurePanel v-if="!product.isLeadForm" :unmount="false">
        <div class="flex items-center text-base font-medium at1024:space-x-2">
            <truck-icon
                class="hidden h-7 w-7 shrink-0 stroke-1 at1024:inline-block"
            ></truck-icon>
            <span
                >Standard shipping is
                <span class="uppercase">{{ shippingCharge }}</span> for this
                item</span
            >
        </div>

        <div class="space-y-6 rounded bg-gray-200 p-5 text-black">
            <div class="flex align-middle">
                <check-icon class="mr-1.5 h-5 w-5 text-green-600"></check-icon>
                <list-bullet-icon class="mr-2 h-5 w-5"></list-bullet-icon>
                <span class="leading-5">
                    Multi-location shipping available
                </span>
            </div>

            <div class="flex align-middle">
                <check-icon
                    v-if="product.shipping?.hasInternationalShipping"
                    class="mr-1.5 h-5 w-5 text-green-600"
                ></check-icon>
                <x-icon v-else class="mr-1 h-5 w-5 text-red-500"></x-icon>
                <globe-icon class="mr-2 h-5 w-5"></globe-icon>
                <span class="leading-5">
                    International shipping is available
                    <template v-if="product.shipping?.hasInternationalShipping">
                        but is not included in the price
                    </template>
                </span>
            </div>

            <div class="flex align-middle">
                <check-icon
                    v-if="product.shipping?.hasMessagingInserts"
                    class="mr-1.5 h-5 w-5 text-green-600"
                ></check-icon>
                <x-icon v-else class="mr-1 h-5 w-5 text-red-500"></x-icon>
                <chat-bubble-left-ellipsis-icon
                    class="mr-2 h-5 w-5"
                ></chat-bubble-left-ellipsis-icon>
                <span class="leading-5">
                    Custom messages are available to include in shipments
                </span>
            </div>

            <div class="flex align-middle">
                <check-icon
                    v-if="product.shipping?.hasExpeditedShipping"
                    class="mr-1.5 h-5 w-5 text-green-600"
                ></check-icon>
                <x-icon v-else class="mr-1 h-5 w-5 text-red-500"></x-icon>
                <truck-icon class="mr-2 h-5 w-5"></truck-icon>
                <span class="leading-5"> Expedited shipping is available </span>
            </div>
        </div>

        <div v-if="product.shipping?.policy">
            <div class="font-semibold">
                <span>Shipping policy or exclusions</span>
            </div>
            <p class="mt-4 whitespace-break-spaces">
                {{ product.shipping.policy }}
            </p>
        </div>

        <div
            v-if="
                product.shipping?.hasInternationalShipping &&
                intlShippingCountries.length > 0
            "
        >
            <div class="font-semibold">
                <span
                    >International shipping is available to the following
                    {{
                        intlShippingCountries.length > 1
                            ? 'destinations'
                            : 'destination'
                    }}:</span
                >
            </div>
            <expandable-set
                limit="20"
                :items="intlShippingCountries"
                class="mt-4"
            ></expandable-set>
        </div>
    </DisclosurePanel>
</template>

<script setup>
import { formatCurrency } from '@/helpers/priceFormatters';
import { DisclosurePanel } from '@headlessui/vue';
import {
    ChatBubbleLeftEllipsisIcon,
    ListBulletIcon,
} from '@heroicons-v2/vue/24/outline';
import { CheckIcon, GlobeIcon, TruckIcon, XIcon } from '@heroicons/vue/outline';
import ExpandableSet from 'components/Global/ExpandableSet.vue';
import { computed, inject } from 'vue';

const product = inject('product');

const shippingCharge = computed(() =>
    product.price?.shipping ? formatCurrency(product.price.shipping) : 'free'
);

const intlShippingCountries = computed(() =>
    product.shipping?.availableShippingCountries
        ? Object.values(product.shipping?.availableShippingCountries)
        : []
);
</script>

<style scoped></style>
