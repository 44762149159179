<template>
    <ul>
        <li v-if="!product.price.isFree">
            <img
                :src="assetUrl('/static/img/icon-price.svg')"
                alt=""
                aria-hidden="true"
            />
            <event-price :price="product.price"></event-price>
        </li>
        <li>
            <img
                :src="assetUrl('/static/img/icon-imac.svg')"
                alt=""
                aria-hidden="true"
            />
            <span
                >{{
                    product.isSelfHosted
                        ? 'Self-hosted virtual'
                        : product.presence
                }}
                event</span
            >
        </li>
        <li v-if="product.participants.max > 0">
            <img
                :src="assetUrl('/static/img/icon-people.svg')"
                alt=""
                aria-hidden="true"
            />
            <span
                >Group size {{ product.participants.min }}-{{
                    product.participants.max
                }}
                participants</span
            >
        </li>
        <li v-if="product.duration > 0">
            <img
                :src="assetUrl('/static/img/icon-clock.svg')"
                alt=""
                aria-hidden="true"
            />
            <span>{{ formatDuration(product.duration) }}</span>
        </li>
    </ul>
</template>

<script setup>
import { assetUrl } from '@/cdn';
import { formatDuration } from '@/helpers/dateTimeFormatters';
import EventPrice from 'components/Global/EventPrice';
import { inject } from 'vue';

const product = inject('product');
</script>

<style scoped>
ul li {
    @apply flex items-center space-x-2 text-sm font-medium;
}
</style>
