<template>
    <VueFinalModal
        class="flex items-center justify-center"
        content-class="max-w-[unset] w-screen at768:w-screen-3/4 at1024:w-screen-2/3 p-4 at768:p-8 bg-white relative"
    >
        <header class="modal__header">
            <button
                class="modal__close"
                aria-label="Close modal"
                @click.prevent="close()"
            ></button>
        </header>
        <div
            v-if="images.length === 1 && !video"
            v-html="images[initialIdx]"
        ></div>
        <image-carousel
            v-else
            :images="images"
            :video="video"
            :start="initialIdx"
        ></image-carousel>
    </VueFinalModal>
</template>

<script setup>
import { VueFinalModal } from 'vue-final-modal';
import ImageCarousel from './ImageCarousel';

const props = defineProps({
    images: {
        type: Array,
        required: true,
    },
    initialIdx: {
        type: Number,
        required: false,
        default: () => 0,
    },
    video: {
        type: String,
        required: false,
        default: () => '',
    },
});

const $emit = defineEmits(['close']);

const close = () => $emit('close');
</script>

<style scoped></style>
