<template>
    <button
        class="rounded-full bg-gray-200 px-2 py-2 text-black"
        @click.stop.prevent="openModal"
        aria-label="Search"
        data-testid="search-field-icon"
    >
        <search-icon class="h-5 w-5"></search-icon>
    </button>
    <div class="modal" id="modal-search" aria-hidden="true">
        <div
            class="fixed bottom-0 left-0 right-0 top-0 z-[100] bg-white bg-opacity-95"
            tabindex="-1"
        >
            <div
                class="mx-auto px-4 pt-2 at1024:max-w-standard at1024:px-8"
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-search-title"
            >
                <header class="text-right">
                    <h2 id="modal-search-title" class="sr-only">
                        Search Events
                    </h2>
                    <button
                        data-search-modal-close
                        class="text-3xl text-blue before:content-['\2715']"
                    >
                        <span class="sr-only">Close modal</span>
                    </button>
                </header>
                <main
                    class="mx-auto mt-16 at768:w-screen-2/3 at1024:w-screen-1/2"
                >
                    <search-box
                        :search-url="searchUrl"
                        label="Enter event name, type, host, or other things you like"
                    ></search-box>
                </main>
            </div>
        </div>
    </div>
</template>

<script>
import { SearchIcon, XIcon } from '@heroicons/vue/solid';
import SearchBox from './SearchBox';

export default {
    name: 'search.modal',
    components: {
        XIcon,
        SearchIcon,
        'search-box': SearchBox,
    },
    props: {
        searchUrl: {
            type: String,
            required: false,
            default: () => '/search',
        },
    },
    methods: {
        openModal() {
            MicroModal.show('modal-search', {
                disableScroll: true,
                closeTrigger: 'data-search-modal-close',
            });
        },
    },
};
</script>
