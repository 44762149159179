<template>
    <div v-if="currentPage > 1 || currentPage < pages">
        <button
            @click.stop.prevent="$emit('pagePrevRequested')"
            class="btn-white btn-short relative mx-2 my-1 inline-flex items-center"
            :class="{ 'btn-disabled': currentPage <= 1 }"
            :disabled="currentPage <= 1"
            data-testid="pagination-prev"
        >
            <chevron-left-icon class="mr-1 w-3"></chevron-left-icon>
            <span class="mr-1">Previous</span>
        </button>
        <button
            @click.stop.prevent="$emit('pageNextRequested')"
            class="btn-white btn-short relative mx-2 my-1 inline-flex items-center"
            :class="{ 'btn-disabled': currentPage >= pages }"
            :disabled="currentPage >= pages"
            data-testid="pagination-next"
        >
            <span class="ml-1">Next</span>
            <chevron-right-icon class="ml-1 w-3"></chevron-right-icon>
        </button>
    </div>
</template>

<script>
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/outline';
export default {
    name: 'SimplePagination',
    components: {
        ChevronLeftIcon,
        ChevronRightIcon,
    },
    props: {
        currentPage: {
            required: true,
            type: Number,
        },
        pages: {
            required: true,
            type: Number,
        },
    },
};
</script>
