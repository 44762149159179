<template>
    <form method="GET" :action="formAction" @submit="submitCheckout">
        <template v-if="!requireVariant || variantProperty.requiresQty">
            <template v-if="product.isEvent">
                <label for="qty" class="block text-sm font-medium text-black"
                    >Approximately how many participants?</label
                >
                <select
                    v-model="qty"
                    name="qty"
                    id="qty"
                    class="mt-2 block w-full rounded border-gray-300 py-3 pl-3 pr-10 text-base at480:text-sm"
                >
                    <option v-for="count in participantRange" :value="count">
                        {{ count }}
                        {{
                            count === qty
                                ? count === 1
                                    ? 'participant'
                                    : 'participants'
                                : ''
                        }}
                    </option>
                </select>
            </template>
            <input
                v-else
                v-model="qty"
                name="qty"
                id="qty"
                type="number"
                class="w-full rounded border-gray-300 px-3 py-2.5 placeholder:text-sm"
                :min="minQty"
                :max="maxQty"
                :placeholder="inputPlaceholder"
                required
                aria-required="true"
            />
        </template>

        <!-- Variant menu -->
        <Listbox
            v-if="variantLabel && variants.length > 0"
            v-model="selectedVariant"
            as="div"
            class="relative mt-4 text-black"
        >
            <ListboxButton
                class="listbox-button flex w-full items-center justify-between gap-x-1.5 rounded border border-gray-300 bg-white p-3 text-sm"
                :class="qtyDropdownClasses"
            >
                <span class="flex items-center">
                    <swatch
                        v-if="selectedVariant.swatch"
                        :swatch="selectedVariant.swatch"
                        :alt="selectedVariant.value"
                    ></swatch>
                    <span class="block truncate">{{
                        selectedVariant.value || `Choose your ${variantLabel}`
                    }}</span>
                </span>
                <ChevronDownIcon
                    class="h-5 w-5 text-gray-500"
                    aria-hidden="true"
                >
                </ChevronDownIcon>
            </ListboxButton>

            <transition
                leave-active-class="transition ease-in duration-100"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
            >
                <ListboxOptions
                    class="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-sm shadow-lg ring-1 ring-gray-300 focus:outline-none"
                >
                    <ListboxOption
                        as="template"
                        v-for="(variant, index) in variants"
                        :index="index"
                        :key="variant.slug"
                        :value="variant"
                        v-slot="{ active, selected }"
                    >
                        <li
                            class="listbox-option relative flex cursor-pointer select-none items-center p-3 ui-selected:bg-gray-200 ui-active:bg-gray-200"
                        >
                            <swatch
                                v-if="variant.swatch"
                                :swatch="variant.swatch"
                                :alt="variant.value"
                            ></swatch>
                            <span class="block truncate">{{
                                variant.value
                            }}</span>
                        </li>
                    </ListboxOption>
                </ListboxOptions>
            </transition>
        </Listbox>

        <button
            type="submit"
            class="btn-orange button-custom-branded mt-4 w-full"
            data-testid="checkout-button"
        >
            {{ checkoutButtonText }}
        </button>
    </form>
</template>

<script setup>
import { trackAddToCartEvent } from '@/helpers/gtm';
import { ServiceRouter } from '@/service-router';
import {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
} from '@headlessui/vue';
import { ChevronDownIcon } from '@heroicons/vue/solid';
import range from 'lodash/range';
import { computed, inject, onMounted, ref, toValue, watch } from 'vue';
import Swatch from './VariantMenu/Swatch.vue';

const emit = defineEmits(['variant-selected', 'qty-changed']);
const product = inject('product');
const storefront = inject('storefront');
const serviceRouter = new ServiceRouter();

const variantProperty = product.variants?.properties[0];
const variantLabel = computed(() => variantProperty?.label);
const requireVariant = product.variants?.variants.length > 0;
const variants = product.variants?.variants;
const selectedVariant = ref({ value: '' });
const qty = ref(product.isEvent ? product.participants.min : '');
const qtyDropdownClasses = ref('');

const minQty = computed(() => {
    const selected = toValue(selectedVariant);
    return product.isEvent
        ? (selected.product
              ? selected.product.participants.min
              : product.participants.min) ?? 1
        : (selected.product
              ? selected.product.shipping?.minQty
              : product.shipping?.minQty) ?? 1;
});
const maxQty = computed(() => product.participants?.max ?? 1000);
const participantRange = computed(() => range(minQty.value, maxQty.value + 1));
const formAction = computed(() => {
    if (requireVariant && toValue(selectedVariant)?.slug) {
        return serviceRouter.storefrontCheckoutUrl(
            storefront.subdomain,
            'book/' + toValue(selectedVariant).slug
        );
    } else if (!requireVariant) {
        return serviceRouter.storefrontCheckoutUrl(
            storefront.subdomain,
            'book/' + product.slug
        );
    }
});

const inputPlaceholder =
    'Quantity' +
    (product.shipping?.minQty > 1
        ? ` - minimum ${product.shipping.minQty} items`
        : '');

const checkoutButtonText = product.isProduct
    ? 'Buy now'
    : product.price.isFree && product.brickVirtual?.isSelfServiceSubscription
    ? 'Start or schedule'
    : storefront.isPublic
    ? 'Make a reservation'
    : 'Submit an event request';

onMounted(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const urlQty = parseInt(searchParams.get('qty') ?? 0);
    if (urlQty >= minQty.value && urlQty <= maxQty.value) {
        qty.value = urlQty;
        emit('qty-changed', urlQty);
    }
    if (requireVariant) {
        const onLoadValue = searchParams.get(toValue(variantLabel));
        if (onLoadValue) {
            const variant = variants.find(
                (variant) => variant.value === onLoadValue
            );
            if (variant) {
                selectedVariant.value = variant;
                emit('variant-selected', selectedVariant.value);
            }
        }
    }
});

const replaceSearchParams = (searchParams) => {
    const url =
        window.location.origin +
        window.location.pathname +
        '?' +
        searchParams.toString();
    window.history.replaceState({ path: url }, '', url);
};

watch(qty, (newVal) => {
    emit('qty-changed', newVal);
    const searchParams = new URLSearchParams(window.location.search);
    if (newVal >= minQty.value && newVal <= maxQty.value) {
        searchParams.set('qty', newVal);
    } else {
        searchParams.delete('qty');
    }
    replaceSearchParams(searchParams);
});

if (requireVariant) {
    watch(selectedVariant, (newVal) => {
        const variantValue = toValue(selectedVariant);
        emit('variant-selected', variantValue);
        if (qty.value < minQty.value) {
            qty.value = minQty.value;
        } else if (qty.value > maxQty.value) {
            qty.value = maxQty.value;
        }
        for (const property in variantValue.product) {
            product[property] = variantValue.product[property];
        }

        qtyDropdownClasses.value = '';
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set(toValue(variantLabel), variantValue.value);
        replaceSearchParams(searchParams);
    });
}

const validate = () => {
    if (!!selectedVariant.value.value) {
        qtyDropdownClasses.value = '';
        return true;
    }
    qtyDropdownClasses.value = 'ring-2 ring-red-500';
    return false;
};

const submitCheckout = (e) => {
    const form = e.target;
    if (
        form.checkValidity() &&
        (!requireVariant || (validate() && toValue(selectedVariant)?.slug))
    ) {
        trackAddToCartEvent(product);
        return true;
    }
    e.preventDefault();
    return false;
};
</script>
