<template>
    <DisclosurePanel v-if="shouldRender" :unmount="false">
        <long-content-toggle v-bind="$attrs">
            <div
                class="flex items-center text-base font-medium at1024:space-x-2"
            >
                <information-circle-icon
                    class="hidden h-7 w-7 shrink-0 stroke-1 at1024:inline-block"
                ></information-circle-icon>
                <span>About this event</span>
            </div>

            <description-copy-block
                :description="howItWorks || ''"
            ></description-copy-block>

            <div v-if="howItWorksFeatures">
                <h4>This host supports the following options:</h4>

                <ul
                    class="relative mt-3 space-y-2 at480:flex at480:space-x-4 at480:space-y-0 at768:static"
                >
                    <li
                        v-if="product.brickVirtual.usesBreakoutRoom"
                        class="relative at480:static at768:relative"
                    >
                        <event-popover
                            panel-class="-translate-y-[135%]"
                            :use-transition="false"
                        >
                            <template #button>
                                <check-icon
                                    class="mr-1.5 h-5 w-5 text-green-600"
                                ></check-icon>
                                <span class="leading-5">Breakout rooms</span>
                            </template>
                            <template #content>
                                <p>This event uses break out rooms.</p>
                            </template>
                        </event-popover>
                    </li>

                    <li v-else class="flex align-middle">
                        <x-icon class="mr-1.5 h-5 w-5 text-red-500"></x-icon>
                        <span class="leading-5">Breakout rooms</span>
                    </li>

                    <li
                        v-if="product.brickVirtual.supportsSameLocation"
                        class="relative at480:static at768:relative"
                    >
                        <event-popover
                            panel-class="-translate-y-[135%]"
                            :use-transition="false"
                        >
                            <template #button>
                                <check-icon
                                    class="mr-1.5 h-5 w-5 text-green-600"
                                ></check-icon>
                                <span class="leading-5">Same location</span>
                            </template>
                            <template #content>
                                <p>
                                    Individuals can join this event remotely or
                                    as a group from the same location using a
                                    single device.
                                </p>
                            </template>
                        </event-popover>
                    </li>
                    <li v-else class="flex align-middle">
                        <x-icon class="mr-1.5 h-5 w-5 text-red-500"></x-icon>
                        <span class="leading-5">Same location</span>
                    </li>

                    <li
                        v-if="product.brickVirtual?.supportsPreassignedTeams"
                        class="relative at480:static at768:relative"
                    >
                        <event-popover
                            panel-class="-translate-y-[135%]"
                            :use-transition="false"
                        >
                            <template #button>
                                <check-icon
                                    class="mr-1.5 h-5 w-5 text-green-600"
                                ></check-icon>
                                <span class="leading-5"
                                    >Pre-assigned teams</span
                                >
                            </template>
                            <template #content>
                                <p>
                                    For this event you can organize your guests
                                    into pre-assigned teams.
                                </p>
                            </template>
                        </event-popover>
                    </li>
                    <li v-else class="flex align-middle">
                        <x-icon class="mr-1.5 h-5 w-5 text-red-500"></x-icon>
                        <span class="leading-5">Pre-assigned teams</span>
                    </li>
                </ul>
            </div>
            <div v-if="hasLanguageSupport">
                <expandable-set
                    prefix="This host offers events in"
                    limit="20"
                    :items="Object.values(product.host?.languages)"
                    class="text-base"
                ></expandable-set>
            </div>
        </long-content-toggle>
    </DisclosurePanel>
</template>

<script>
export default {
    inheritAttrs: false,
};
</script>

<script setup>
import { DisclosurePanel } from '@headlessui/vue';
import { computed, inject, toValue } from 'vue';

import {
    CheckIcon,
    InformationCircleIcon,
    XIcon,
} from '@heroicons/vue/outline';
import ExpandableSet from 'components/Global/ExpandableSet.vue';
import LongContentToggle from 'components/Global/LongContentToggle';
import DescriptionCopyBlock from 'components/ListingDetail/DescriptionCopyBlock.vue';
import EventPopover from './Popover';

const product = inject('product');

const howItWorksFeatures = computed(
    () =>
        product.brickVirtual?.usesBreakoutRoom ||
        product.brickVirtual?.supportsSameLocation ||
        product.brickVirtual?.supportsPreassignedTeams
);
const howItWorks = computed(() => product.howItWorks?.trim());

const hasLanguageSupport = computed(
    () =>
        product.host?.hasMultilingualOfferings &&
        Object.values(product.host?.languages || {}).length > 0
);

const shouldRender = computed(
    () =>
        toValue(howItWorksFeatures) ||
        toValue(howItWorks) ||
        toValue(hasLanguageSupport)
);
</script>

<style scoped></style>
