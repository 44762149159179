<template>
    <a
        target="_blank"
        :href="product.catalog_url"
        class="relative flex flex-col items-start"
        draggable="true"
        @click="open"
        ref="anchor"
    >
        <x-circle-icon
            @click.prevent="$emit('detailsClose')"
            class="absolute left-1 top-1 w-8 text-gray-400"
        ></x-circle-icon>

        <img
            v-if="product.thumbnail_url"
            :src="product.thumbnail_url"
            class="w-full rounded-t object-cover object-center"
        />

        <div class="space-y-2 p-3">
            <div class="flex flex-row items-start gap-x-2">
                <h3 class="text-sm font-semibold leading-tight text-black">
                    {{ product.name }}
                </h3>
                <reviews-badge
                    class="space-x-0.5"
                    :rating-score="product.rating_score || 0"
                    :rating-count="product.rating_count"
                ></reviews-badge>
            </div>
            <div class="mt-2 flex justify-start space-x-2 text-xs text-black">
                <span v-if="product.primary_category">{{
                    product.primary_category.name
                }}</span>
                <span
                    >{{ product.minimum_participants }}-{{
                        product.maximum_participants
                    }}
                    people</span
                >
                <span>{{ product.duration }} min</span>
            </div>
            <div>
                <event-price
                    class="flex grow items-center space-x-1.5 text-sm font-semibold text-black"
                    :price="product.price"
                >
                </event-price>
            </div>
        </div>
    </a>
</template>

<script setup>
import { XCircleIcon } from '@heroicons-v2/vue/24/solid';
import EventPrice from 'components/Global/EventPrice';
import ReviewsBadge from 'components/Global/ReviewsBadge';
import { onMounted, ref } from 'vue';

const props = defineProps({
    product: {
        type: Object,
        required: true,
    },
});

const anchor = ref(null);
const isDragging = ref(false);

const $emit = defineEmits(['detailsClose']);

onMounted(() => {
    anchor.value.addEventListener(
        'dragstart',
        (event) => (isDragging.value = true)
    );
});

const open = (event) => {
    if (isDragging.value) {
        event.preventDefault();
        isDragging.value = false;
    }
};
</script>

<style scoped></style>
