import { ServiceRouter } from '@/service-router';
import axios from 'axios';
import { computed, ref } from 'vue';

const serviceRouter = new ServiceRouter();
const sorts = {
    highest_rated: 'Highest Rated',
    lowest_rated: 'Lowest Rated',
    most_recent: 'Most Recent',
};

export function useReviewFetch(fetchUri, jsonLdSchema) {
    const reviews = ref([]);
    const reviewsMeta = ref({});
    const reviewsLoading = ref(false);
    const page = ref(1);
    const pageCount = computed(() => reviewsMeta.value.last_page ?? 1);
    const sort = ref('highest_rated');

    const formatSortLabel = (currentSort) =>
        currentSort === sort.value
            ? 'Sort by: ' + sorts[currentSort]
            : sorts[currentSort];

    const getReviews = async (newPage = null) => {
        if (reviewsLoading.value) {
            return;
        }
        if (newPage) {
            page.value = newPage;
        }
        reviewsLoading.value = true;
        try {
            const response = await axios.get(
                serviceRouter.eventManagerUrl(fetchUri, {
                    page: page.value,
                    sort: sort.value,
                })
            );
            reviews.value = response.data.data;
            reviewsMeta.value = response.data.meta;
        } finally {
            reviewsLoading.value = false;
        }
    };

    const jsonLd = computed(() => {
        if (!reviews.value.length) {
            return '';
        }
        const schema = {
            ...jsonLdSchema,
            review: [],
        };
        for (const review of reviews.value) {
            schema.review.push({
                '@context': 'https://schema.org',
                '@type': 'Review',
                reviewRating: {
                    '@type': 'Rating',
                    ratingValue: review.rating,
                },
                reviewBody: review.comment,
                author: {
                    '@type': 'Person',
                    name: review.author_name || 'anonymous',
                },
                publisher: {
                    '@type': 'Organization',
                    name: 'Elevent',
                },
            });
        }
        return JSON.stringify(schema);
    });

    return {
        reviews,
        reviewsLoading,
        getReviews,
        page,
        pageCount,
        sorts,
        sort,
        formatSortLabel,
        jsonLd,
    };
}
