<template>
    <div>
        <div
            v-if="searchQuery"
            class="mb-2 inline-block bg-peach px-1 text-sm text-black"
        >
            {{ searchQuery }}
        </div>

        <grid-header :total-results="totalResults" title="Search Results">
            <template v-slot:results.copy
                ><span>
                    {{ totalResults }} results based on your search</span
                ></template
            >
        </grid-header>
        <placeholder-grid v-if="loading" class="tiles-grid"></placeholder-grid>
        <div v-else>
            <product-grid
                class="tiles-grid"
                data-testid="search-results-grid"
                :products="products"
                :currency="productListMeta.currency"
            ></product-grid>
            <simple-pagination
                class="mt-8 flex justify-center at1024:mt-12"
                :current-page="currentPage"
                :pages="pageCount"
                @page-next-requested="loadResults(currentPage + 1)"
                @page-prev-requested="loadResults(currentPage - 1)"
            ></simple-pagination>
        </div>
    </div>
</template>

<script>
import {
    deconstructUrlParams,
    usePushHistoryState,
} from '@/composables/usePushHistoryState';
import axios from 'axios';
import { ref, shallowRef } from 'vue';
import VueScrollTo from 'vue-scrollto';
import UsesPagination from '../mixins/usePagination';
import GridHeader from './ProductList/GridHeader';
import PlaceholderGrid from './ProductList/PlaceholderGrid';
import ProductGrid from './ProductList/ProductGrid';
import SimplePagination from './SimplePagination';

export default {
    name: 'SearchResults',
    mixins: [UsesPagination],
    setup() {
        return {
            products: shallowRef([]),
            productListMeta: ref({}),
            loading: ref(true),
            usePushHistoryState: usePushHistoryState,
            deconstructUrlParams: deconstructUrlParams,
        };
    },
    created() {
        this.initialLoad();
        window.onpopstate = (e) => {
            this.initialLoad();
        };
    },
    components: {
        ProductGrid,
        GridHeader,
        SimplePagination,
        PlaceholderGrid,
    },
    props: {
        sortOptions: {
            type: Array,
            required: false,
        },
        searchQuery: {
            type: String,
            required: true,
        },
        resultsEndpointUrl: {
            type: String,
            required: true,
        },
    },
    methods: {
        initialLoad() {
            let params = this.deconstructUrlParams();
            this.loadResults(params.page || 1, false);
        },
        async loadResults(page, pushesHistory = true) {
            page = page || this.currentPage;
            const params = new URLSearchParams();
            params.append('q', this.searchQuery);
            params.append('page', page);

            this.loading = true;
            if (this.products.length > 0) {
                VueScrollTo.scrollTo('body', 250, {
                    force: false,
                });
            }

            try {
                const response = await axios.get(this.resultsEndpointUrl, {
                    params,
                });
                if (pushesHistory) {
                    let pushToHistory = {
                        q: this.searchQuery,
                    };
                    if (page > 1) {
                        pushToHistory['page'] = page;
                    } else {
                        delete pushToHistory['page'];
                    }
                    this.usePushHistoryState(pushToHistory);
                }
                this.products = response.data.products || [];
                this.productListMeta = response.data.meta || {};
            } catch (e) {
                this.products = [];
                this.productListMeta = {
                    page_count: 1,
                    total_results: 0,
                };
                throw e;
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
