import Category from './Category.vue';
import EventDate from './EventDate.vue';
import GroupSizeBudget from './GroupSizeBudget.vue';
import Results from './Results.vue';

export default {
    budget: GroupSizeBudget,
    date: EventDate,
    category: Category,
    results: Results,
};
