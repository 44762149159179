<template>
    <span v-if="basePrice || perPersonPrice" class="price">
        <template v-if="hasRangePricing"> Starting at </template>
        <span v-else-if="hasDiscount" class="strike-price">
            <template v-if="!!basePrice">
                {{ formatCurrency(originalBasePrice) }}
            </template>
            <template v-if="!!basePrice && !!perPersonPrice"
                >&nbsp;&plus;&nbsp;</template
            >
            <template v-if="!!perPersonPrice">
                {{ formatCurrency(originalPerPersonPrice) }}
            </template>
        </span>
        <template v-if="!!basePrice">
            {{ formatCurrency(basePrice) }}
        </template>
        <template v-if="!!basePrice && !!perPersonPrice"
            >&nbsp;&plus;&nbsp;</template
        >
        <template v-if="!!perPersonPrice">
            {{ formatCurrency(perPersonPrice) }}
        </template>
        / {{ perPersonPrice > 0 ? 'person' : 'group' }}
    </span>
</template>

<script setup>
import { formatCurrency } from '@/helpers/priceFormatters';
import { computed, toValue } from 'vue';

const props = defineProps({
    price: {
        type: Object,
        required: true,
    },
});

const hasRangePricing = computed(() => props.price.hasRangePricing);
const discountPercentage = computed(
    () =>
        (props.price.discountPercentage ||
            props.price.discount_percentage ||
            0) / 100
);
const hasDiscount = computed(() => !!toValue(discountPercentage));
const basePrice = computed(() => props.price.base || 0);
const perPersonPrice = computed(
    () => props.price.perPerson || props.price.per_person || 0
);
const originalBasePrice = computed(() =>
    Math.round(toValue(basePrice) / (1 - toValue(discountPercentage)))
);
const originalPerPersonPrice = computed(() =>
    Math.round(toValue(perPersonPrice) / (1 - toValue(discountPercentage)))
);
</script>
