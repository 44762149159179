<template>
    <div>
        <h3 class="mb-6 text-lg font-semibold">Group size &amp; budget</h3>

        <div class="flex flex-col gap-y-6 text-sm">
            <div class="flex flex-col gap-y-2">
                <label for="modal-filter-budget-group_size"
                    >Approximately how many guests</label
                >

                <input
                    type="number"
                    name="group_size"
                    min="1"
                    id="modal-filter-budget-group_size"
                    class="block w-full max-w-[330px] rounded-md border-gray-300 py-3 text-base font-normal at768:text-sm"
                    placeholder="Add your guest count"
                    v-model="groupSize"
                />
            </div>

            <div class="flex flex-col gap-y-4">
                <label>Price per person</label>

                <Slider
                    v-model="budget"
                    :min="minPricePerPerson"
                    :max="maxPricePerPerson"
                    :tooltips="false"
                    class="mb-2 ml-2 max-w-[330px]"
                />

                <div class="flex flex-row items-center gap-x-4">
                    <span
                        class="price-input-wrapper relative inline-block w-full"
                    >
                        <input
                            type="number"
                            name="min_price"
                            :min="minPricePerPerson"
                            id="modal-filter-budget-min"
                            class="block w-full rounded-md border-gray-300 pb-3 pl-6 pt-6 text-base font-normal at768:text-sm"
                            v-model="budget[0]"
                            @blur="validateMinPriceInput"
                        />
                        <label
                            for="modal-filter-budget-min"
                            class="absolute left-2.5 top-4 z-10 origin-[0] -translate-y-3 scale-75 transform text-sm !font-normal text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-3 peer-focus:scale-75 peer-focus:text-blue-600 dark:text-gray-400 peer-focus:dark:text-blue-500"
                            >Min price</label
                        >
                    </span>
                    <span
                        class="price-input-wrapper relative inline-block w-full"
                    >
                        <input
                            type="number"
                            name="max_price"
                            :min="budget[0]"
                            :max="maxPricePerPerson"
                            id="modal-filter-budget-max"
                            class="block w-full rounded-md border-gray-300 pb-3 pl-6 pt-6 text-base font-normal at768:text-sm"
                            placeholder="Max price"
                            v-model="budget[1]"
                            @blur="validateMaxPriceInput"
                        />
                        <label
                            for="modal-filter-budget-max"
                            class="absolute left-2.5 top-4 z-10 origin-[0] -translate-y-3 scale-75 transform text-sm !font-normal text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-3 peer-focus:scale-75 peer-focus:text-blue-600 dark:text-gray-400 peer-focus:dark:text-blue-500"
                            >Max price</label
                        >
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useBudgetFilter } from '@/composables/useBudgetFilter';
import { useCollectionFilters } from '@/composables/useCollectionFilters';
import Slider from '@vueform/slider';
import { watch } from 'vue';

const props = defineProps({
    current: {
        required: false,
        default: () => null,
    },
});

const { queueFilter } = useCollectionFilters();
const {
    groupSize,
    budget,
    validateMinPriceInput,
    validateMaxPriceInput,
    minPricePerPerson,
    maxPricePerPerson,
} = useBudgetFilter(
    props.current ? parseInt(props.current[0]) || null : null,
    props.current ? [props.current[1], props.current[2]] : null
);

watch(
    [groupSize, budget],
    ([newSizeValue, newBudgetValue]) => {
        let filterData = null;
        if (!isNaN(parseInt(newSizeValue)) && newSizeValue > 0) {
            filterData = [groupSize.value, ...budget.value];
        }
        if (!groupSize.value) {
            //default value for group size if any change to budget
            groupSize.value = 20;
        }
        queueFilter('budget', filterData);
    },
    { deep: true }
);
</script>

<style src="@vueform/slider/themes/default.css"></style>
<style scoped>
label {
    @apply font-medium;
}
span.price-input-wrapper:before {
    content: '$';
    position: absolute;
    top: 43%;
    left: 10px;
    z-index: 1;
    @apply font-medium;
}
</style>
