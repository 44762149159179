<template>
    <ul>
        <li v-for="(label, option) in sortOptions" class="shrink-0">
            <a
                href="#"
                @click.prevent="setSortOption(option)"
                class="hover:font-semibold hover:underline hover:underline-offset-2"
                :class="{
                    'font-semibold underline underline-offset-2':
                        currentSort == option,
                }"
                :title="label"
                :data-testid="`sort-option-${option}`"
                >{{ label }}</a
            >
        </li>
    </ul>
</template>

<script setup>
import { useCollectionFilters } from '@/composables/useCollectionFilters';
import { ref } from 'vue';

const props = defineProps({
    default: {
        type: String,
        required: false,
        default: () => '',
    },
    sortOptions: {
        type: Object,
        required: true,
    },
});
const { applyFilter } = useCollectionFilters();
const sortingVisible = ref(false);
const currentSort = ref(
    props.default ? props.default : Object.keys(props.sortOptions)[0]
);

const setSortOption = (sortOption) => {
    sortingVisible.value = false;
    currentSort.value = sortOption;
    applyFilter('sort', currentSort.value);
};
</script>

<style scoped>
a::before {
    content: attr(title);
    @apply invisible block h-0 font-semibold;
}
</style>
