<template>
    <div class="flex">
        <Component
            v-for="type in listTypes"
            :is="PageLedeBottomElements[type]"
            class="flex flex-row items-center space-x-2 hover:text-orange"
            @click.prevent="openModal(type)"
            @link-to-description-click="linkToDescriptionClick(type)"
        ></Component>

        <modals-container></modals-container>
    </div>
</template>

<script setup>
import { ModalsContainer, useModal } from 'vue-final-modal';

import PageLedeBottomElements from './BottomLine/index';
import InfoModals from './BottomLine/Modals/index';

const listTypes = Object.keys(PageLedeBottomElements);

const $emit = defineEmits(['linkToDescriptionClick']);

const { open, close, patchOptions } = useModal({
    attrs: {
        onClose: () => {
            close();
        },
    },
});

const openModal = (type) => {
    if (InfoModals[type]) {
        patchOptions({
            component: InfoModals[type],
            attrs: {
                onLinkToDescriptionClick: () => {
                    close().then(() => $emit('linkToDescriptionClick', type));
                },
            },
        });

        open();
    }
};
const linkToDescriptionClick = (type) => {
    $emit('linkToDescriptionClick', type);
};
</script>

<style scoped></style>
