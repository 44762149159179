<template>
    <a
        v-if="product.hasShipments"
        href="#"
        class="font-medium underline underline-offset-2 hover:text-orange"
        >Kit included</a
    >
</template>

<script setup>
import { inject } from 'vue';

const product = inject('product');
</script>

<style scoped></style>
