<template>
    <button
        v-if="availableFilters.length > 0"
        @click.prevent="open"
        class="relative"
    >
        <slot name="button-content">
            <span class="flex gap-x-2">
                <adjustments-horizontal-icon
                    class="h-5 w-5"
                ></adjustments-horizontal-icon>
                <span>All filters</span>
            </span>
        </slot>
        <span
            v-if="showAppliedFilterCount && filterCount > 0"
            class="absolute -right-2.5 -top-2 flex h-5 w-5 items-center justify-center rounded-full bg-black p-1 text-xs font-normal text-white"
            >{{ filterCount }}</span
        >
        <modals-container></modals-container>
    </button>
</template>

<script setup>
import { useCollectionFilters } from '@/composables/useCollectionFilters';
import { AdjustmentsHorizontalIcon } from '@heroicons-v2/vue/24/outline';
import FiltersModal from 'components/ProductList/ProductFilter/FiltersModal';
import { computed, toValue } from 'vue';
import { ModalsContainer, useModal } from 'vue-final-modal';

const props = defineProps({
    showAppliedFilterCount: {
        type: Boolean,
        required: false,
        default: true,
    },
});

const { availableFilters, appliedVisibleFilters } = useCollectionFilters();
const filterCount = computed(() => toValue(appliedVisibleFilters).length);

const { open, close } = useModal({
    component: FiltersModal,
    attrs: {
        onClose: () => {
            close();
        },
    },
});
</script>
