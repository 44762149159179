<template>
    <div
        class="at1024:rounded-lg at1024:border at1024:border-gray-300 at1024:p-4 at1200:p-8"
    >
        <h4 class="mb-3 text-lg font-medium text-black">
            Interested in
            {{ product.isProduct ? 'this product' : 'booking this event' }}?
        </h4>
        <button
            class="btn-orange button-custom-branded mx-auto mb-3 block w-full max-w-96"
            @click.stop.prevent="open"
            data-testid="quote-button"
        >
            Request a quote
        </button>
        <p class="text-center text-sm">
            You will receive a quote within 1 business day.
        </p>
        <modals-container></modals-container>
    </div>
</template>

<script setup>
import { inject } from 'vue';
import { ModalsContainer, useModal } from 'vue-final-modal';
import ModalForm from './QuoteLeadFormModal/ModalForm.vue';

const product = inject('product');

const { open } = useModal({
    component: ModalForm,
    attrs: {},
    keepAlive: true,
    //events => on submission event, destroy
});
</script>
