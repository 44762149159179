<template>
    <button
        v-if="canRender"
        v-bind="$attrs"
        @click.prevent="open"
        class="btn-blue btn-pill btn-tall whitespace-nowrap"
        data-testid="event-wizard-button"
    >
        <slot>Find the perfect event</slot>
    </button>
    <modals-container></modals-container>
</template>
<script setup>
import { useCollectionFilters } from '@/composables/useCollectionFilters';
import EventWizard from 'components/EventWizard.vue';
import { onMounted, ref } from 'vue';
import { ModalsContainer, useModal } from 'vue-final-modal';

const { availableFilters, filterOptions } = useCollectionFilters();
const canRender = ref(false);

// wizard cannot function without these filters & options
onMounted(() => {
    canRender.value =
        availableFilters.includes('budget') ||
        availableFilters.includes('event_date') ||
        filterOptions.categories.length > 0;
});

const { open, close } = useModal({
    component: EventWizard,
    attrs: {
        onClose: () => {
            close();
        },
    },
});
</script>
