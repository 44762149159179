<template>
    <info-modal>
        <div class="flex flex-col space-y-6">
            <header class="flex flex-row items-center space-x-4 text-left">
                <users-icon class="h-8 w-8 stroke-1"></users-icon>
                <h3 class="font-semibold">Adjustable Headcount</h3>
            </header>

            <main class="space-y-6 text-sm">
                Headcount can be adjusted up and down
                <template
                    v-if="
                        product.host.adjustableParticipantPercentage &&
                        product.host.adjustableParticipantPolicyIsBooking
                    "
                    >by {{ product.host.adjustableParticipantPercentage }}%
                </template>
                <template
                    v-if="product.host.adjustableParticipantLeadTime === 0"
                >
                    at any time.
                </template>
                <template v-else>
                    up to
                    <span class="font-semibold"
                        >{{
                            product.host.adjustableParticipantLeadTime
                        }}
                        days</span
                    >
                    before the event.</template
                >
            </main>
        </div>
    </info-modal>
</template>

<script setup>
import { inject } from 'vue';

import { UsersIcon } from '@heroicons/vue/outline';
import InfoModal from 'components/Global/InfoModal';

const product = inject('product');

const $emit = defineEmits(['linkToDescriptionClick']);
</script>

<style scoped></style>
