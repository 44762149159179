<template>
    <div ref="marker">
        <div class="marker relative hover:cursor-pointer">
            <span
                v-if="count > 1"
                class="margin-auto absolute top-2 z-10 w-full text-center text-sm text-white"
                >{{ countOutput }}</span
            >
            <map-pin-solid
                v-if="count > 1"
                class="marker-icon w-8 text-blue"
            ></map-pin-solid>
            <map-pin-hole
                v-else
                class="marker-icon w-8 text-blue"
            ></map-pin-hole>
        </div>

        <div
            class="marker-details fixed hidden rounded-md shadow-2xl shadow-gray-700"
        >
            <Splide
                class="w-[235px] rounded-md"
                :options="carouselOptions"
                aria-label="Location results"
            >
                <SplideSlide v-for="product in products">
                    <advanced-marker-detail
                        :product="product"
                        class="marker-detail margin-auto max-w-[235px] rounded-md bg-white"
                        @details-close="handleDetailsClose"
                    ></advanced-marker-detail>
                </SplideSlide>
            </Splide>
        </div>
    </div>
</template>

<script setup>
import MapPinHole from 'components/Global/Icons/MapPinHole';
import MapPinSolid from 'components/Global/Icons/MapPinSolid';
import { computed, ref } from 'vue';

import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import AdvancedMarkerDetail from 'components/ProductList/ProductFilter/AdditionalFilters/LatLngBoundsFilter/AdvancedMarkerDetail';

const marker = ref(null);

const props = defineProps({
    products: {
        type: Array,
        required: false,
        default: [],
    },
});

const count = computed(() => {
    return props.products.length;
});

const countOutput = computed(() => {
    return count.value > 9 ? '9+' : count.value;
});

const carouselOptions = {
    type: 'slide',
    speed: 500,
    autoplay: false,
    isNavigation: false,
    arrows: count.value > 1,
    drag: false,
    keyboard: true,
};

const handleDetailsClose = () => {
    try {
        marker.value.classList.remove('opened');
    } catch (e) {}
};
</script>

<style scoped>
.opened {
    .marker-details {
        @apply block;
    }
    &.above {
        .marker-details {
            @apply -translate-y-[110%];
        }
    }
    &.center {
        .marker-details {
            @apply left-1/2 -translate-x-1/2;
        }
    }
    &.left {
        .marker-details {
            @apply right-0;
        }
    }
}
.highlighted .marker,
.opened .marker,
.marker:hover {
    .marker-icon {
        @apply w-9 text-orange focus:outline-0;
    }
}
</style>
<style>
.marker-details {
    .splide__list {
        @apply items-end;
    }

    .splide__arrow {
        @apply !bg-white;
    }
}
</style>
