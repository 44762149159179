<template>
    <a v-if="product.host?.instantBookEnabled" href="#" @click.prevent="">
        <span class="flex flex-row items-center space-x-2">
            <lightning-bolt-icon class="h-6 w-6"></lightning-bolt-icon
            ><span class="font-medium underline underline-offset-2"
                >Instant book</span
            >
        </span>
    </a>
</template>

<script setup>
import { LightningBoltIcon } from '@heroicons/vue/outline';
import { inject } from 'vue';

const product = inject('product');
</script>

<style scoped></style>
