<template>
    <div>
        <h4 class="mb-2 text-lg text-black">
            Have a question about this
            {{ product.isProduct ? 'gift' : 'event' }}?
        </h4>
        <div class="mt-2 flex flex-wrap gap-4">
            <button
                type="button"
                @click.stop.prevent="open"
                class="btn-white btn-short grow text-sm at768:px-5"
                data-testid="ask-question-button"
            >
                Ask a question
            </button>
        </div>
        <modals-container></modals-container>
    </div>
</template>

<script setup>
import { inject, onMounted } from 'vue';
import { ModalsContainer, useModal } from 'vue-final-modal';
import ModalForm from './AskQuestionModal/ModalForm';

const product = inject('product');

const { open } = useModal({
    component: ModalForm,
    attrs: {},
    keepAlive: true,
    //events => on submission event, destroy
});

onMounted(() => {
    if (window.location.hash.indexOf('inquire') >= 0) {
        open();
    }
});
</script>
