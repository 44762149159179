import {
    deconstructUrlParams,
    usePushHistoryState,
} from '@/composables/usePushHistoryState';
import { pick } from 'lodash';
import { computed, ref, shallowRef } from 'vue';

// filter options from the server set when initializing the composable
const filterOptions = {};
// page & sort are always available. this list may be added to when initializing the composable
const availableFilters = ['page', 'sort'];
// applied filters that are currently active
const appliedFilters = shallowRef({});
// filters that have been queued to be applied later
const queuedFilters = ref({});
// all available filters and their data types
const filterTypes = {
    physical_type: { dataType: 'value', persist_in_ls: false },
    budget: { dataType: 'array', persist_in_ls: false },
    has_shipments: { dataType: 'boolean', persist_in_ls: false },
    event_date: { dataType: 'value', persist_in_ls: false },
    categories: { dataType: 'array', persist_in_ls: false },
    durations: { dataType: 'array', persist_in_ls: false },
    instant_book: { dataType: 'boolean', persist_in_ls: false },
    international_shipping: { dataType: 'boolean', persist_in_ls: false },
    is_hosted: { dataType: 'boolean', persist_in_ls: false },
    video_platforms: { dataType: 'array', persist_in_ls: false },
    lang: { dataType: 'array', persist_in_ls: false },
    lat_lng_bounds: { dataType: 'array', persist_in_ls: false },
};
// filters that the user can interact with in the modal or grid
const appliedVisibleFilters = computed(() => {
    return Object.keys(appliedFilters.value).filter(
        (filter) =>
            filter !== 'page' &&
            filter !== 'sort' &&
            filter !== 'lat_lng_bounds'
    );
});

// apply (or clear) a single filter
// used for left-hand filters that are applied immediately as the user interacts with them
function applyFilter(filter, value) {
    const filters = { ...appliedFilters.value };
    if (value === null || (Array.isArray(value) && value.length === 0)) {
        delete filters[filter];
    } else {
        filters[filter] = value;
    }
    // reset page when filtering
    if (filter !== 'page') {
        delete filters['page'];
    }
    appliedFilters.value = filters;
    persistFilters();
}

// apply multiple filters at once
function applyFilters(filters, append = false) {
    if (append) {
        filters = { ...appliedFilters.value, ...filters };
    }
    for (const key in filters) {
        if (
            filters[key] === null ||
            (Array.isArray(filters[key]) && filters[key].length === 0)
        ) {
            delete filters[key];
        }
    }
    delete filters['page']; // reset page when filtering
    appliedFilters.value = filters;
    persistFilters();
}

// queue a filter to be applied later
// used for modal filters that are set individually and applied all at once
function queueFilter(filter, value) {
    queuedFilters.value[filter] = value;
}

// apply all queued filters
function applyQueuedFilters(append = true) {
    if (Object.keys(queuedFilters.value).length > 0) {
        applyFilters(queuedFilters.value, append);
        queuedFilters.value = {};
    }
}

// clear all filters, minus the "virtual" ones that the user interacts with outside the modal/grid
function clearFilters() {
    applyFilters(pick(appliedFilters.value, ['lat_lng_bounds', 'sort']));
}

// persist filters to the url and local storage
function persistFilters() {
    const filters = { ...appliedFilters.value };
    usePushHistoryState(filters);
    const filtersToPersist = filtersPersistedInLS();
    for (const key in filters) {
        if (!filtersToPersist.includes(key)) {
            delete filters[key];
        }
    }
    localStorage.setItem('collection-filters', JSON.stringify(filters));
}
function filtersByDataType(type) {
    return Object.keys(filterTypes).filter(
        (key) => filterTypes[key].dataType === type
    );
}
function filtersPersistedInLS() {
    return Object.keys(filterTypes).filter(
        (key) => filterTypes[key].persist_in_ls
    );
}

export function useCollectionFilters(
    initialOptions = null,
    initialAvailableFilters = null,
    defaultSort = null
) {
    if (initialOptions) {
        for (const key in initialOptions) {
            filterOptions[key] = initialOptions[key];
        }
    }
    if (initialAvailableFilters) {
        for (const filter of initialAvailableFilters) {
            availableFilters.push(filter);
        }
    }

    // pull filters from the url and/or local storage when initialized
    if (initialOptions) {
        // page & sort are "virtual" filters and are intentionally not persisted to local storage
        const page =
            parseInt(new URLSearchParams(window.location.search).get('page')) ||
            1;
        const urlSort = new URLSearchParams(window.location.search).get('sort');
        const sort = urlSort in initialOptions.sorts ? urlSort : defaultSort;
        const urlFilters = deconstructUrlParams(
            filtersByDataType('array'),
            filtersByDataType('boolean'),
            filtersByDataType('value')
        );
        const lsFilters =
            JSON.parse(localStorage.getItem('collection-filters')) ?? {};
        appliedFilters.value = {
            ...lsFilters,
            ...urlFilters,
            page,
            sort,
        };
        // remove any filters that are not available
        for (const key in appliedFilters.value) {
            if (!availableFilters.includes(key)) {
                delete appliedFilters.value[key];
            }
        }
    }
    return {
        availableFilters,
        filterOptions,
        appliedFilters,
        appliedVisibleFilters,
        applyFilter,
        applyFilters,
        queuedFilters,
        queueFilter,
        applyQueuedFilters,
        clearFilters,
    };
}
