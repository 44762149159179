<template>
    <a class="cursor-pointer font-semibold hover:underline" @click="open"
        ><slot></slot
    ></a>
    <modals-container></modals-container>
</template>

<script setup>
import { ModalsContainer, useModal } from 'vue-final-modal';
import EleventGuaranteeModal from './EleventGuaranteeModal';

const { open, close } = useModal({
    component: EleventGuaranteeModal,
    attrs: {
        onClose: () => {
            close();
        },
    },
});
</script>
