<template>
    <a
        v-if="product.isInPerson"
        href="#"
        @click.prevent="$emit('linkToDescriptionClick')"
    >
        <span class="flex flex-row items-center space-x-2">
            <office-building-icon class="h-6 w-6"></office-building-icon
            ><span class="font-medium underline underline-offset-2">{{
                product.brickInPerson?.isTypeWillComeToYou
                    ? 'Your venue'
                    : product.brickInPerson?.isTypeLocation
                    ? 'Venue provided'
                    : 'Venue provided or your venue'
            }}</span>
        </span>
    </a>
</template>

<script setup>
import { OfficeBuildingIcon } from '@heroicons/vue/outline';
import { inject } from 'vue';

const product = inject('product');
const $emit = defineEmits(['linkToDescriptionClick']);
</script>

<style scoped></style>
