<template>
    <div class="relative">
        <section
            v-for="type in filterTypes"
            class="mr-8 border-b border-gray-300 pb-4"
            :class="loading ? 'opacity-50' : ''"
        >
            <Component
                :current="appliedFilters[type]"
                :is="GridFilters[type]"
                :key="componentKey(type)"
            ></Component>
        </section>
        <div
            v-if="loading"
            class="absolute -left-4 -top-5 z-10 h-full w-full animate-pulse rounded-md opacity-80"
        >
            <div class="h-full w-full rounded-md bg-gray-300"></div>
        </div>
    </div>
</template>

<script setup>
import { useCollectionFilters } from '@/composables/useCollectionFilters';
import intersection from 'lodash/intersection';
import { toValue } from 'vue';
import GridFilters from './ProductFilter/GridFilters/index';

const props = defineProps({
    loading: {
        type: Boolean,
        required: false,
        default: () => false,
    },
});

const { availableFilters, appliedFilters } = useCollectionFilters();

const filterTypes = intersection(
    Object.keys(GridFilters),
    toValue(availableFilters)?.length
        ? toValue(availableFilters)
        : Object.keys(GridFilters)
);

const componentKey = (type) => {
    return btoa(toValue(appliedFilters)[type]);
};
</script>

<style scoped>
:deep(input[type='radio']) {
    @apply h-5 w-5 cursor-pointer checked:bg-black checked:shadow-black focus-within:bg-black
    focus-within:shadow-black focus:bg-black focus:shadow-black focus-visible:bg-black
    focus-visible:shadow-black active:bg-black active:shadow-black;
}
:deep(input[type='checkbox']) {
    @apply h-4 w-4 checked:bg-black checked:shadow-black;
}
</style>
