<template>
    <a :href="product.catalog_url" :key="product.uuid" class="product-tile">
        <div class="relative w-full overflow-hidden rounded-t-lg bg-gray-200">
            <img
                v-if="product.thumbnail_url"
                :src="product.thumbnail_url"
                class="h-full w-full object-cover object-center"
                width="400"
                height="267"
                :alt="product.name + ' thumbnail'"
            />
            <span
                v-if="product.tags.length > 0"
                class="absolute left-3 top-3 flex items-start space-x-1.5"
            >
                <span
                    v-for="tag in product.tags"
                    class="whitespace-nowrap rounded-md border-gray-300 bg-gray-200 px-1 py-1.5 text-xs font-semibold uppercase"
                >
                    <shield-check-icon
                        class="-mt-1 mr-0.5 inline-block h-4 w-4 text-orange"
                    ></shield-check-icon>
                    {{ tag }}
                </span>
            </span>
        </div>
        <div class="flex grow flex-col justify-between p-2 pb-3">
            <div class="pt-3">
                <span
                    v-if="product.rating_count > 0"
                    class="mb-2 flex items-center space-x-1 text-sm"
                >
                    <star-icon class="w-[1.2rem] text-orange"></star-icon>
                    <span v-if="product.rating_score" class="font-semibold"
                        ><span class="sr-only">Average rating:</span>
                        {{ parseFloat(product.rating_score).toFixed(1) }}</span
                    >
                    <span class="text-gray"
                        ><span class="sr-only">Number of ratings:</span>({{
                            product.rating_count
                        }})</span
                    >
                </span>
                <div class="ml-0.5">
                    <h3 class="font-semibold leading-tight text-black">
                        {{ product.name }}
                    </h3>
                    <div
                        class="mt-2 flex justify-start space-x-4 text-xs text-black"
                    >
                        <template v-if="product.type === 'event'">
                            <span v-if="product.is_lead_form">
                                Price upon request
                            </span>
                            <event-price v-else :price="price"></event-price>
                            <span v-if="product.maximum_participants > 0"
                                >{{ product.minimum_participants }}-{{
                                    product.maximum_participants
                                }}
                                people</span
                            >
                            <span v-if="product.duration > 0"
                                >{{ product.duration }} min</span
                            >
                        </template>
                        <template v-else-if="product.is_lead_form">
                            Price upon request
                        </template>
                        <product-price v-else :price="price"></product-price>
                    </div>
                    <p class="mt-2 text-sm text-gray-500">
                        {{ product.short_description }}
                    </p>
                </div>
            </div>
            <div
                class="mt-2.5 flex flex-wrap justify-start text-xs font-semibold text-black"
            >
                <span
                    class="mr-1.5 mt-1.5 block whitespace-nowrap rounded-full border border-gray-300 bg-gray-200 px-2 py-1"
                    >{{
                        product.type === 'event'
                            ? product.presence_type === 'Physical'
                                ? 'In-person'
                                : 'Virtual'
                            : 'Gift'
                    }}</span
                >
                <span
                    v-if="product.primary_category"
                    class="mr-1.5 mt-1.5 block whitespace-nowrap rounded-full border border-gray-300 bg-gray-200 px-2 py-1"
                    >{{
                        product.primary_category.tag_name ||
                        product.primary_category.name
                    }}</span
                >
                <span
                    v-if="product.host?.instant_book_enabled"
                    class="mt-1.5 block whitespace-nowrap rounded-full border border-gray-300 bg-gray-200 px-2 py-1"
                    ><lightning-bolt-icon
                        class="-mt-0.5 mr-1 inline-block h-3 w-3 fill-orange"
                    ></lightning-bolt-icon
                    >Instant book</span
                >
            </div>
        </div>
    </a>
</template>
<script setup>
import {
    LightningBoltIcon,
    ShieldCheckIcon,
    StarIcon,
} from '@heroicons/vue/solid';
import EventPrice from 'components/Global/EventPrice.vue';
import ProductPrice from 'components/Global/ProductPrice.vue';

const props = defineProps({
    product: {
        type: Object,
        required: true,
    },
});

const price = {
    base: props.product.price.base,
    perPerson: props.product.price.per_person,
    discountPercentage: props.product.price.discount_percentage,
    hasRangePricing: props.product.has_range_pricing,
};
</script>
<style scoped>
:deep(.price .shipping-price) {
    @apply text-gray-500;
}
</style>
