<template>
    <a v-if="shouldRender" href="#" @click.prevent="">
        <span class="flex flex-row items-center space-x-2">
            <users-icon class="h-6 w-6"></users-icon>
            <span class="font-medium underline underline-offset-2"
                >Adjustable headcount</span
            >
        </span>
    </a>
</template>

<script setup>
import { inject } from 'vue';

import { UsersIcon } from '@heroicons/vue/outline';

const product = inject('product');
const shouldRender =
    (product.host?.adjustableParticipantPolicy === 'booking' ||
        product.host?.adjustableParticipantPolicy === 'listing') &&
    product.host?.adjustableParticipantLeadTime !== null;
</script>

<style scoped></style>
