<template>
    <VueFinalModal
        class="flex justify-center at768:items-start"
        content-class="relative mt-0 flex w-full max-w-none flex-col gap-y-6 rounded bg-white pt-2 at768:mt-16 at768:max-h-[90vh] at768:w-2/3 at768:max-w-[620px]"
        data-testid="modal-filters-container"
    >
        <header
            class="relative flex items-center justify-center gap-x-2 border-b border-gray-300 pb-4 pt-2 text-xl font-semibold"
        >
            <adjustments-horizontal-icon
                class="h-6 w-6"
            ></adjustments-horizontal-icon>

            <span>Filters</span>

            <x-circle-icon
                @click.prevent="$emit('close')"
                class="absolute left-2 top-0 h-10 w-10 cursor-pointer hover:text-black/90"
            ></x-circle-icon>
        </header>

        <div
            class="z-0 flex max-h-none min-h-[200px] flex-col space-y-6 overflow-y-auto px-4 pb-24 at768:px-8"
        >
            <section
                v-for="type in filterTypes"
                class="border-b border-gray-300 pb-8"
            >
                <Component
                    :current="appliedFilters[type]"
                    :is="ModalFilters[type]"
                ></Component>
            </section>
        </div>

        <div
            class="absolute bottom-0 left-0 flex w-full flex-row gap-x-4 rounded-b bg-gray-200 px-6 py-4 text-sm font-medium"
        >
            <button
                @click.prevent="clearAndClose"
                type="reset"
                class="rounded border border-black bg-white px-10 py-2 hover:bg-gray-100/90"
                data-testid="modal-filters-clear-button"
            >
                Clear all
            </button>
            <button
                :disabled="!canSubmit"
                @click.prevent="applyAndClose"
                type="submit"
                class="grow rounded bg-black px-6 py-2 text-white hover:bg-black/90 disabled:bg-gray-600/70"
                data-testid="modal-filters-filter-button"
            >
                Filter results
            </button>
        </div>
    </VueFinalModal>
</template>

<script setup>
import { useCollectionFilters } from '@/composables/useCollectionFilters';
import { AdjustmentsHorizontalIcon } from '@heroicons-v2/vue/24/outline';
import { XCircleIcon } from '@heroicons-v2/vue/24/solid';
import intersection from 'lodash/intersection';
import { computed } from 'vue';
import { VueFinalModal } from 'vue-final-modal';
import ModalFilters from './ModalFilters/index';

const $emit = defineEmits(['close']);
const {
    availableFilters,
    filterOptions,
    appliedFilters,
    queuedFilters,
    applyQueuedFilters,
    clearFilters,
} = useCollectionFilters();

const filterTypes = intersection(
    Object.keys(ModalFilters),
    availableFilters?.length ? availableFilters : Object.keys(ModalFilters)
);

if (!filterOptions.categories.length) {
    const categoryIndex = filterTypes.indexOf('categories');
    if (categoryIndex !== -1) {
        filterTypes.splice(categoryIndex, 1);
    }
}

const canSubmit = computed(() => Object.keys(queuedFilters.value).length > 0);

function applyAndClose() {
    applyQueuedFilters();
    $emit('close');
}
function clearAndClose() {
    clearFilters();
    $emit('close');
}
</script>
