<template>
    <div
        class="at1024:rounded-lg at1024:border at1024:border-gray-300 at1024:p-4 at1200:p-8"
    >
        <h3 class="sr-only">Book this event</h3>
        <div
            class="mx-auto max-w-[500px] space-y-4 at1024:max-w-full at1200:space-y-6"
        >
            <div v-if="!product.price.isFree" class="flex flex-row">
                <event-price
                    :price="product.price"
                    class="grow text-2xl font-semibold text-black at1024:text-xl at1200:text-2xl"
                ></event-price>

                <reviews-badge
                    :rating-score="product.ratingScore || 0"
                    :rating-count="product.ratingCount"
                    :hide-rating-count="true"
                ></reviews-badge>
            </div>
            <div
                v-if="
                    product.published && (product.isSalable || product.variants)
                "
            >
                <checkout-form
                    ref="variantMenu"
                    @qty-changed="(qty) => (participantCount = qty)"
                    @variant-selected="variantSelected"
                ></checkout-form>

                <table
                    v-if="!product.price.isFree"
                    class="mt-6 w-full text-sm at768:w-6/12 at1024:w-full"
                >
                    <tr v-if="product.price.base">
                        <td
                            class="py-2 pr-4"
                            :class="{
                                'pb-4':
                                    !product.price.perPerson &&
                                    !product.hasShipments,
                            }"
                        >
                            Event price
                        </td>
                        <td
                            class="py-2 text-right"
                            :class="{
                                'pb-4':
                                    !product.price.perPerson &&
                                    !product.hasShipments,
                            }"
                        >
                            {{ formatCurrency(product.price.base) }}
                        </td>
                    </tr>
                    <tr v-if="product.price.perPerson">
                        <td
                            class="pr-4 pt-2"
                            :class="{ 'pb-4': !product.hasShipments }"
                        >
                            {{ participantCount }} people x
                            {{ formatCurrency(product.price.perPerson) }}
                            pp
                        </td>
                        <td
                            class="pt-2 text-right"
                            :class="{ 'pb-4': !product.hasShipments }"
                        >
                            {{
                                formatCurrency(
                                    product.price.perPerson * participantCount
                                )
                            }}
                        </td>
                    </tr>
                    <tr v-if="product.hasShipments">
                        <td class="pb-4 pr-4 pt-2">Standard shipping</td>
                        <td class="pb-4 pt-2 text-right">FREE</td>
                    </tr>
                    <tr class="border-t border-gray-300 text-lg">
                        <td class="pr-4 pt-4 font-semibold">
                            Total before taxes (USD)
                        </td>
                        <td class="pt-4 text-right font-semibold">
                            {{
                                formatCurrency(
                                    product.price.base +
                                        product.price.perPerson *
                                            participantCount
                                )
                            }}
                        </td>
                    </tr>
                </table>
            </div>
            <div v-else>
                <p>Unfortunately, this event is no longer available.</p>
                <a
                    v-if="product.primaryCategoryUrl"
                    :href="product.primaryCategoryUrl"
                    class="btn-orange button-custom-branded mt-4 block w-full"
                >
                    See similar events
                </a>
            </div>
        </div>
    </div>
</template>

<script setup>
import { formatCurrency } from '@/helpers/priceFormatters';
import EventPrice from 'components/Global/EventPrice';
import ReviewsBadge from 'components/Global/ReviewsBadge.vue';
import CheckoutForm from 'components/ListingDetail/CheckoutForm.vue';
import { inject, ref, shallowRef } from 'vue';

const storefront = inject('storefront');
const product = inject('product');
const participantCount = ref(product.participants.min);
const selectedVariant = shallowRef({});

const emit = defineEmits(['variant-selected']);
function variantSelected(variant) {
    selectedVariant.value = variant;
    emit('variant-selected', variant);
}
</script>
