<template>
    <div>
        <label
            for="search"
            class="sr-only at900:not-sr-only at900:block at900:pb-3 at900:text-xl at900:font-semibold"
            >{{ label }}</label
        >
        <div class="relative">
            <div
                class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
            >
                <search-icon
                    class="h-5 w-5 text-gray at900:h-6 at900:w-6"
                ></search-icon>
            </div>
            <input
                v-model="searchQuery"
                @keyup.enter="onEnter"
                id="search"
                name="search"
                class="block w-full rounded-lg border-gray bg-white py-2 pl-9 pr-3 text-base leading-5 placeholder-gray focus:border focus:border-black focus:shadow-none focus:outline-none at900:rounded-sm at900:py-6 at900:pl-12 at900:pr-3 at900:text-xl at900:placeholder-transparent"
                placeholder="Search events"
                type="search"
            />
        </div>
    </div>
</template>

<script>
import { SearchIcon, XIcon } from '@heroicons/vue/solid';
import { ref } from 'vue';

export default {
    name: 'search.box',
    watch: {
        initialSearchQuery(newVal) {
            this.searchQuery = newVal;
        },
    },
    setup() {
        let params = new URLSearchParams(window.location.search);
        return {
            searchQuery: ref(params.get('q') || ''),
        };
    },
    components: {
        SearchIcon,
        XIcon,
    },
    props: {
        searchUrl: {
            type: String,
            required: false,
            default: () => '/search',
        },
        label: {
            type: String,
            required: false,
            default: () => 'Search',
        },
    },
    methods: {
        onEnter() {
            if (this.searchQuery) {
                window.location =
                    this.searchUrl +
                    '?q=' +
                    encodeURIComponent(this.searchQuery);
            }
        },
    },
};
</script>
