<template>
    <DisclosurePanel v-if="product.kitContents" id="kit-details">
        <div class="flex items-center text-base font-medium at1024:space-x-2">
            <archive-icon
                class="hidden h-7 w-7 shrink-0 stroke-1 at1024:inline-block"
            ></archive-icon>
            <span
                >Each guest of the event will receive a kit with the following
                items.</span
            >
        </div>

        <description-copy-block
            :description="kitContents"
        ></description-copy-block>

        <div v-if="product.supportedSubstitutions">
            <div class="flex align-middle">
                <img
                    :src="assetUrl('/static/img/dinner-setting.svg')"
                    alt=""
                    loading="lazy"
                    aria-hidden="true"
                    class="mr-2 h-5"
                />
                <span class="font-semibold leading-5"
                    >Supported dietary restrictions:</span
                >
            </div>
            <ul class="mt-3 at768:flex at768:flex-wrap at768:space-x-2.5">
                <li
                    v-for="sub in product.supportedSubstitutions"
                    class="mt-2 flex align-middle at768:mt-0"
                >
                    <check-icon
                        class="mr-1.5 h-5 w-5 text-green-600"
                    ></check-icon>
                    <span class="leading-5">{{ sub }}</span>
                </li>
            </ul>
        </div>
    </DisclosurePanel>
</template>

<script setup>
import { assetUrl } from '@/cdn';
import { DisclosurePanel } from '@headlessui/vue';
import { computed, inject } from 'vue';

import { ArchiveIcon, CheckIcon } from '@heroicons/vue/outline';
import DescriptionCopyBlock from 'components/ListingDetail/DescriptionCopyBlock.vue';

const product = inject('product');

const kitContents = computed(() => product.kitContents?.trim());
</script>

<style scoped></style>
