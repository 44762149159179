<script>
import OndemandProductList from 'components/OndemandProductList.vue';
import MicroModal from 'micromodal';
import EventDetail from './Components/EventDetail';
import EventWizardButton from './Components/EventWizardButton';
import FilterableProductList from './Components/FilterableProductList';
import CmsFaqs from './Components/Global/CmsFaqs';
import CookieConsent from './Components/Global/CookieConsent';
import EleventGuaranteeModalLink from './Components/Global/EleventGuaranteeModalLink';
import EventFaqs from './Components/Global/EventFaqs';
import GradientHero from './Components/Global/GradientHero';
import GlobalHeader from './Components/Global/Header';
import HomeHero from './Components/Home/Hero';
import HostDetail from './Components/HostDetail';
import ProductDetail from './Components/ProductDetail';
import SearchResults from './Components/SearchResults';

export default {
    created() {
        MicroModal.init();
    },
    components: {
        CmsFaqs,
        CookieConsent,
        EleventGuaranteeModalLink,
        EventDetail,
        EventFaqs,
        EventWizardButton,
        FilterableProductList,
        GlobalHeader,
        GradientHero,
        HomeHero,
        HostDetail,
        OndemandProductList,
        ProductDetail,
        SearchResults,
    },
};
</script>
