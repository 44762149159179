<template>
    <div>
        <img
            :src="product.images[0]"
            alt=""
            aria-hidden="true"
            class="mb-3 rounded-md"
            loading="lazy"
        />
        <ul>
            <li class="text-xl font-semibold leading-tight at720:text-2xl">
                {{ product.name }}
            </li>
            <li
                v-if="product.ratingCount"
                class="mt-2 flex items-center space-x-1 text-sm"
            >
                <star-icon class="w-4 text-orange"></star-icon>
                <span class="font-semibold">
                    <span class="sr-only">Average rating:</span>
                    {{ product.ratingScore }}
                </span>
                <span>
                    <span class="sr-only">Number of ratings:</span>({{
                        product.ratingCount
                    }})
                </span>
            </li>
        </ul>
        <event-breakdown
            v-if="product.isEvent"
            class="mt-3 space-y-4"
        ></event-breakdown>
        <product-breakdown
            v-else-if="product.isProduct"
            class="mt-3 space-y-4"
        ></product-breakdown>
    </div>
</template>

<script setup>
import { StarIcon } from '@heroicons/vue/solid';
import { inject } from 'vue';
import EventBreakdown from './EventBreakdown';
import ProductBreakdown from './ProductBreakdown';

const product = inject('product');
</script>
