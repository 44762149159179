<template>
    <nav>
        <div class="hidden items-center at768:flex at768:space-x-8">
            <template v-for="(item, idx) in items">
                <a
                    v-if="item.menu_type !== 'flyout'"
                    :href="item.uri"
                    class="nav-top inline-flex cursor-pointer items-center px-1 pt-1 font-medium text-neutral-600"
                    :title="item.title"
                    :target="item.target"
                    :class="[
                        activeGroup === item.group ? 'active ' : '',
                        item.class,
                    ]"
                    >{{ item.label }}</a
                >
                <flyout
                    v-else
                    :item="item"
                    :active="activeGroup === item.group"
                ></flyout>
            </template>
        </div>
    </nav>
</template>

<script>
import Flyout from 'components/Global/Header/CatalogNavigation/Flyout';
export default {
    name: 'CatalogNavigation',
    props: {
        items: {
            required: true,
            type: Array,
        },
        pageIdx: {
            required: false,
            type: Number,
            default: () => -1,
        },
        activeGroup: {
            type: String,
            required: false,
        },
    },
    components: {
        Flyout,
    },
};
</script>

<style scoped>
.nav-top:hover {
    @apply text-black;
}
.nav-top.active {
    @apply text-black underline decoration-2 underline-offset-[12px];
}
</style>
