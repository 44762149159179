<template>
    <div>
        <h3 class="mb-6 text-lg font-semibold">Supported video platforms</h3>

        <ul class="grid grid-cols-1 gap-y-3 text-sm at720:grid-cols-2">
            <li
                v-for="(platform, idx) in platforms"
                class="flex flex-row items-center space-x-4"
            >
                <input
                    :id="`modal-filter-platform-${idx}`"
                    @change="handleSelection(platform.value, $event)"
                    type="checkbox"
                    :value="platform.value"
                    class="rounded border-gray-300"
                    :checked="selectedPlatforms.indexOf(platform.value) > -1"
                />
                <label :for="`modal-filter-platform-${idx}`">{{
                    platform.label
                }}</label>
            </li>
        </ul>
    </div>
</template>

<script setup>
import { useCollectionFilters } from '@/composables/useCollectionFilters';
import { ref } from 'vue';

const props = defineProps({
    current: {
        required: false,
        default: () => null,
    },
});

const { filterOptions, queueFilter } = useCollectionFilters();
const platforms = filterOptions.videoPlatforms;
const selectedPlatforms = ref(props.current ? [...props.current] : []);

const handleSelection = (platform, event) => {
    if (event.target.checked) {
        selectedPlatforms.value.push(platform);
        selectedPlatforms.value.sort();
    } else {
        try {
            let index = selectedPlatforms.value.indexOf(platform);
            if (index !== -1) {
                selectedPlatforms.value.splice(index, 1);
            }
        } catch (e) {}
    }
    queueFilter('video_platforms', selectedPlatforms.value);
};
</script>

<style scoped></style>
