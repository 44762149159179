<template>
    <Menu
        v-slot="{ open }"
        @mouseenter="hovered = true"
        @mouseleave="hovered = false"
        :data-headlessui-state="hovered ? 'open' : ''"
        as="div"
    >
        <a
            :href="item.uri"
            :target="item.target"
            :class="item.class"
            :title="item.title"
        >
            <menu-button
                as="span"
                :disabled="true"
                :href="item.uri"
                :key="item.id"
                :aria-expanded="hovered"
                :data-headlessui-state="hovered ? 'open' : ''"
                class="sub-nav-item pointer-events-auto inline-flex w-full cursor-pointer items-center justify-center px-2 py-2 text-sm font-medium at1200:px-4"
            >
                {{ item.label }}
                <ChevronDownIcon
                    v-if="items"
                    class="ml-0.5 h-4 w-4"
                    aria-hidden="true"
                />
            </menu-button>
        </a>
        <transition
            enter-active-class="transition duration-50 ease-out"
            enter-from-class="transform scale-95 opacity-0"
            enter-to-class="transform scale-100 opacity-100"
            leave-active-class="transition duration-50 ease-in"
            leave-from-class="transform scale-100 opacity-100"
            leave-to-class="transform scale-95 opacity-0"
        >
            <menu-items
                v-if="items"
                v-show="hovered"
                static
                :data-headlessui-state="hovered ? 'open' : ''"
                :key="item.id"
                class="menu-items mt-1.5 w-max origin-top-right rounded-lg rounded-md border border-black bg-white bg-white py-6 pl-4 pr-12 text-left shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none at768:absolute at768:left-1/2 at768:z-20 at768:-translate-x-1/2"
            >
                <div
                    class="absolute left-0 top-0 flex w-full -translate-y-1/2 items-center justify-center"
                >
                    <span
                        class="hidden h-4 w-4 rotate-45 rounded-sm border border-white border-l-black border-t-black bg-white at768:block"
                        role="none"
                    ></span>
                </div>
                <ul class="flex flex-col space-y-3" role="none">
                    <menu-item
                        as="li"
                        v-slot="{ active }"
                        v-for="i in items"
                        class="menu-item inline-block"
                    >
                        <a
                            :href="i.uri"
                            :title="i.title"
                            class="group inline-block w-full items-center rounded-md text-sm font-medium text-black"
                            :class="i.class"
                            :target="i.target"
                        >
                            {{ i.label }}
                        </a>
                    </menu-item>
                </ul>
            </menu-items>
        </transition>
    </Menu>
</template>

<script>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { ChevronDownIcon } from '@heroicons/vue/solid';
import { ref } from 'vue';
export default {
    name: 'SubNavigationItem',
    setup() {
        return {
            hovered: ref(false),
        };
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    components: {
        ChevronDownIcon,
        Menu,
        MenuButton,
        MenuItems,
        MenuItem,
    },
    computed: {
        items() {
            return this.item?.children?.length > 0 ? this.item.children : false;
        },
    },
};
</script>

<style scoped>
.sub-nav-item[data-headlessui-state='open'],
.menu-item a:hover {
    text-stroke: 0.3px black;
    -webkit-text-stroke: 0.3px black;
}
</style>
