<template>
    <div>
        <!-- main image -->
        <div
            class="relative w-full"
            :class="useTallLayout ? 'pt-[100%]' : 'pt-[66.666%]'"
        >
            <span
                class="absolute bottom-0 left-0 right-0 top-0"
                v-html="selectedImage"
            ></span>
            <iframe
                v-show="hasVideo && selectedImageIdx === videoIdx"
                class="absolute bottom-0 left-0 right-0 top-0 m-auto h-full w-full rounded-lg"
                :src="embedVideoUrl"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
            ></iframe>
        </div>

        <!-- image thumb carousel -->
        <div
            v-if="availableImages.length > 1"
            class="relative mt-4 flex flex-row flex-nowrap gap-x-2 overflow-x-auto px-0.5 py-0.5"
        >
            <template v-for="(image, idx) in availableImages">
                <span
                    @click="selectedImageIdx = idx"
                    class="relative inline-block max-w-[80px] shrink-0 cursor-pointer"
                    :class="{
                        'rounded-lg outline outline-2 outline-black':
                            idx === selectedImageIdx,
                    }"
                >
                    <span v-inline v-html="image"></span>
                    <play-circle-icon
                        v-if="hasVideo && idx === videoIdx"
                        class="absolute bottom-0 left-0 right-0 top-0 m-auto w-7 text-gray-700"
                    ></play-circle-icon>
                </span>
            </template>
        </div>
    </div>
</template>

<script setup>
import { PlayCircleIcon } from '@heroicons-v2/vue/24/solid';
import { computed, ref, watch } from 'vue';

const props = defineProps({
    images: {
        type: Array,
        required: true,
    },
    video: {
        type: String,
        required: false,
        default: () => '',
    },
    useTallLayout: {
        type: Boolean,
        required: false,
        default: () => false,
    },
    modelValue: {
        type: Object,
        required: false,
        default: () => {},
    },
});

const videoIdx = 1;

const availableImages = Object.values(props.images); //clone
const hasVideo = ref(false);
const embedVideoUrl = ref('');

if (props.video) {
    const videoUrl = new URL(props.video);
    const vid = videoUrl.searchParams.get('v');
    if (vid) {
        embedVideoUrl.value = `https://www.youtube.com/embed/${vid}`;
        const videoHtml = `<picture><img width="1080" height="720" class="h-full object-center object-cover rounded-lg w-full" src="https://i.ytimg.com/vi_webp/${vid}/maxresdefault.webp" alt="Product video"></picture>`;
        availableImages.splice(videoIdx, 0, videoHtml);
        hasVideo.value = true;
    }
}

const selectedImageIdx = ref(0);

const selectedImage = computed(() => {
    return availableImages[selectedImageIdx.value];
});

watch(
    () => props.modelValue,
    (newVal) => {
        if (newVal?.image) {
            const index = Object.keys(props.images).findIndex(
                (key) => key === newVal.image
            );
            if (index > -1) {
                selectedImageIdx.value = index;
            }
        }
    }
);
</script>

<style scoped></style>
