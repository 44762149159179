<template>
    <div data-testid="event-wizard-step-budget">
        <main>
            <h1>What is your approximate group size and budget per person?</h1>
            <div class="content flex flex-col gap-y-6">
                <div class="flex flex-col gap-y-2">
                    <label for="wizard_group_size"
                        >Estimate your group size</label
                    >
                    <input
                        type="number"
                        name="group_size"
                        min="1"
                        id="wizard_group_size"
                        class="block w-full max-w-[330px] rounded-md border-gray-300 py-3 text-base font-normal at768:text-sm"
                        placeholder="Add your guest count"
                        v-model="groupSize"
                    />
                </div>

                <div class="flex flex-col gap-y-4">
                    <label>Set your budget range</label>
                    <Slider
                        v-model="budget"
                        :min="minPricePerPerson"
                        :max="maxPricePerPerson"
                        :tooltips="false"
                        class="mb-2 ml-2 max-w-[330px]"
                    />

                    <div class="flex flex-row items-center gap-x-4">
                        <span
                            class="price-input-wrapper relative inline-block w-full"
                        >
                            <input
                                type="number"
                                name="min_price"
                                :min="minPricePerPerson"
                                id="wizard_min_price"
                                class="block w-full rounded-md border-gray-300 pb-3 pl-6 pt-6 text-base font-normal at768:text-sm"
                                v-model="budget[0]"
                                @blur="validateMinPriceInput"
                            />
                            <label
                                for="wizard_min_price"
                                class="absolute left-2.5 top-4 z-10 origin-[0] -translate-y-3 scale-75 transform text-sm !font-normal text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-3 peer-focus:scale-75 peer-focus:text-blue-600 dark:text-gray-400 peer-focus:dark:text-blue-500"
                                >Min price</label
                            >
                        </span>
                        <span
                            class="price-input-wrapper relative inline-block w-full"
                        >
                            <input
                                type="number"
                                name="max_price"
                                :min="budget[0]"
                                :max="maxPricePerPerson"
                                id="wizard_max_price"
                                class="block w-full rounded-md border-gray-300 pb-3 pl-6 pt-6 text-base font-normal at768:text-sm"
                                placeholder="Max price"
                                v-model="budget[1]"
                                @blur="validateMaxPriceInput"
                            />
                            <label
                                for="wizard_max_price"
                                class="absolute left-2.5 top-4 z-10 origin-[0] -translate-y-3 scale-75 transform text-sm !font-normal text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-3 peer-focus:scale-75 peer-focus:text-blue-600 dark:text-gray-400 peer-focus:dark:text-blue-500"
                                >Max price</label
                            >
                        </span>
                    </div>
                </div>
            </div>
        </main>
        <modal-footer @next-click="proceed"></modal-footer>
    </div>
</template>
<script setup>
import { useBudgetFilter } from '@/composables/useBudgetFilter';
import { useEventWizard } from '@/composables/useEventWizard';
import Slider from '@vueform/slider';
import ModalFooter from '../ModalFooter.vue';

const { nextStep, pushFilter, getFilter } = useEventWizard();
const {
    groupSize,
    budget,
    validateGroupSizeInput,
    validateMinPriceInput,
    validateMaxPriceInput,
    minPricePerPerson,
    maxPricePerPerson,
} = useBudgetFilter('');

const filter = getFilter('budget');
if (filter) {
    groupSize.value = filter[0];
    budget.value = filter.slice(1);
}

const proceed = () => {
    validateMinPriceInput();
    validateMaxPriceInput();
    let groupSizeDefault = '';
    if (
        parseInt(budget.value[0]) !== 0 ||
        parseInt(budget.value[1]) !== maxPricePerPerson
    ) {
        // default a group size if the user has set a budget
        groupSizeDefault = 20;
    }
    validateGroupSizeInput(groupSizeDefault);
    if (groupSize.value === '') {
        pushFilter('budget', null);
    } else {
        pushFilter('budget', [groupSize.value, ...budget.value]);
    }
    nextStep();
};
</script>
