<template>
    <div>
        <h3 class="mb-6 text-lg font-semibold">Categories</h3>

        <ul class="grid grid-cols-1 gap-y-3 text-sm at720:grid-cols-2">
            <li
                v-for="(category, idx) in categories"
                class="flex flex-row items-center space-x-4"
            >
                <input
                    :id="`modal-filter-categories-${idx}`"
                    @change="handleSelection(category.slug, $event)"
                    type="checkbox"
                    :value="category.slug"
                    class="rounded border-gray-300"
                    :checked="
                        findIndex(
                            selectedCategories,
                            (v) => v == category.slug
                        ) > -1
                    "
                />
                <label :for="`modal-filter-categories-${idx}`">{{
                    category.name
                }}</label>
            </li>
        </ul>
    </div>
</template>

<script setup>
import { useCollectionFilters } from '@/composables/useCollectionFilters';
import findIndex from 'lodash/findIndex';
import { ref } from 'vue';

const { filterOptions, queueFilter } = useCollectionFilters();
const categories = filterOptions.categories;

const props = defineProps({
    current: {
        required: false,
        default: null,
    },
});

const selectedCategories = ref(props.current ? [...props.current] : []);

const handleSelection = (category, event) => {
    if (event.target.checked) {
        selectedCategories.value.push(category);
        selectedCategories.value.sort();
    } else {
        try {
            let index = findIndex(
                selectedCategories.value,
                (v) => v == category
            );
            if (index !== -1) {
                selectedCategories.value.splice(index, 1);
            }
        } catch (e) {}
    }
    queueFilter('categories', selectedCategories.value);
};
</script>
