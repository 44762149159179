<template>
    <span v-if="product.duration > 0">{{ product.duration }} min</span>
</template>

<script setup>
import { inject } from 'vue';

const product = inject('product');
</script>

<style scoped></style>
