require('./bootstrap');
require('./hubspot');

import { createApp } from 'vue';

import VueSplide from '@splidejs/vue-splide';
import VueCookies from 'vue-cookies';
import { createVfm } from 'vue-final-modal';
import 'vue-final-modal/style.css';

import App from './App.vue';

const app = createApp(App);

app.config.globalProperties.document = window.document;

app.directive('inline', (element) => {
    element.replaceWith(...element.children);
});

app.use(createVfm()).use(VueSplide).use(VueCookies).mount('#app');

import '../css/app.pcss';
